/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {useEffect} from "react";
import {BaseDataStore, logger, ResourceDataStore, ValidationRuleDataStore} from "..";

export const useResourcesAndValidations = (store: BaseDataStore, componentLogName: string) => {
  useEffect(() => {
    (store.resourceDataStore as ResourceDataStore).load();
    logger.log(componentLogName + " - loaded localizations");
  }, [store.resourceDataStore]);

  useEffect(() => {
    (store.validationRuleDataStore as ValidationRuleDataStore).load();
    logger.log(componentLogName + " - loaded validation rules");
  }, [store.validationRuleDataStore]);
}

export const useValidations = (store: BaseDataStore, componentLogName: string) => {
  useEffect(() => {
    (store.validationRuleDataStore as ValidationRuleDataStore).load();
    logger.log(componentLogName + " - loaded validation rules");
  }, [store.validationRuleDataStore]);
}